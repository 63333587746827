module.exports = function (eventType) {
    let assetsUrl = 'assets/images/workshops/';
    if (window.location.pathname.includes('workshop')){ assetsUrl = '../' + assetsUrl }
    if (window.location.pathname.includes('live')){ assetsUrl = '../' + assetsUrl }
    switch (eventType) {
        case 'java':
            return assetsUrl + 'workshop-java-icon.svg';
        case 'docker':
            return assetsUrl + 'workshop-docker-icon.svg';
        case 'cs':
            return assetsUrl + 'workshop-csharp-icon.svg';
        case 'js':
            return assetsUrl + 'workshop-js-icon.svg';
        case 'private':
            return assetsUrl + 'workshop-private-icon.svg';
        case 'java-csharp':
            return assetsUrl + 'workshop-java-csharp-icon.svg';
        case 'ts':
            return assetsUrl + 'workshop-ts-icon.svg';
        case 'ts-js':
            return assetsUrl + 'workshop-ts-js-icon.svg';
        case 'go':
            return assetsUrl + 'workshop-go-icon.svg';
        case 'go-py':
            return assetsUrl + 'workshop-go-py-icon.svg';
        case 'aws':
            return assetsUrl + 'workshop-aws-icon.svg';
        case 'k8s':
            return assetsUrl + 'workshop-kubernetes-icon.svg';
        case 'fuzzing':
            return assetsUrl + 'workshop-fuzzing-icon.svg';
        case 'openapi':
            return assetsUrl + 'workshop-openapi-icon.png';
        case 'solidity':
            return assetsUrl + 'workshop-solidity-icon.svg';
        case 'ethereum':
            return assetsUrl + 'workshop-ethereum-icon.svg';
        case 'python':
            return assetsUrl + 'workshop-python-icon.svg';
        case 'ruby':
            return assetsUrl + 'workshop-ruby-icon.svg';
        case 'c':
            return assetsUrl + 'workshop-c-icon.svg';
       default:
            return '';
    }
}
