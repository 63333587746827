module.exports = function (eventTypes) {
    switch (eventTypes.length) {
        case 1:
            return '--one';
        case 2:
            return '--two';
        case 3:
            return '--three';
        default:
            return '';
    }
}
