var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-md-6 col-lg-4 discuss-row-margin\">\n    <div class=\"discuss\">\n        <!-- DISCUSS SNIPPETS ARTICLE -->\n        <article class=\"discuss-article d-flex flex-column justify-content-between\">\n\n                <a class=\"text-decoration-none\" href=\"/post?slug="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"slug") : depth0), depth0))
    + "&id="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n            <div class=\"discuss-content d-flex flex-column justify-content-between\">\n\n                <div class=\"discuss-content__text\" >\n                    <div class=\"discuss-content__date\">\n                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"created_at") : depth0), depth0))
    + "</span>\n                    </div> <!--/.discuss-content__date-->\n                    <h3>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h3>\n                    <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"views") : depth0), depth0))
    + " views</p>\n                   <!-- <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"image_url") : depth0), depth0))
    + "\" class=\"discuss-content__image\"/> -->\n                </div> <!--/.discuss-content__text-->\n\n            </div> <!--/.discuss-content-->\n                </a>\n\n                <br/>\n        </article><!--/.discuss-article-->\n        <!-- END DISCUSS SNIPPETS ARTICLE -->\n    </div> <!--/.discuss-->\n</div> <!--/.col-md-4-->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"discuss") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":9}}})) != null ? stack1 : "");
},"useData":true});