import 'popper.js';
import 'bootstrap';
import 'jquery';
import './scss/styles.scss';

import Prism from 'prismjs';
import 'paginationjs';

let workshopTemplate = require('./hbs/handlebars/workshop.handlebars');
let discussTemplate = require('./hbs/handlebars/discuss.handlebars');
let blogTemplate = require('./hbs/handlebars/blog.handlebars');

// Import PrismJS extensions
(function () {
    'use strict';

    $(function () {


        // Workshops
        if (window.location.pathname.includes('workshop') || window.location.pathname.includes('live')) {
            $('.workshop-pagination').pagination({
                dataSource: function (done) {
                    let workshopUrl = 'code/workshop.json';
                    if (window.location.pathname.includes('workshop')){ workshopUrl = '../' + workshopUrl }
                    if (window.location.pathname.includes('live')){ workshopUrl = '../' + workshopUrl }
                    $.ajax({
                        type: 'GET',
                        url: workshopUrl,
                        success: function (response) {
                            done(response.events);
                        }
                    })
                },
                pageSize: 6,
                ulClassName: 'pagination justify-content-center',
                prevText: '&lsaquo;',
                nextText: '&rsaquo;',
                callback: function (data, pagination) {

                    const html = workshopTemplate({
                        workshops: data
                    });

                    $('.workshops-items .row').html(html);

                    // Workshops tooltips
                    $('[data-toggle="popover"]').popover({
                        html: true,
                        content: function () {
                            const content = $(this).attr('data-popover-content');
                            return $(content).children('.popover-body').html();
                        }
                    })
                }
            });
        }

        // Latest posts from Discuss on homepage
        if (window.location.pathname == '/') {
            $('.discuss-pagination').pagination({
                dataSource: function (done) {
                    fetch('https://discuss.secdim.com/c/news/5/l/latest.json')
                    .then((response) => response.json())
                    .then((data) => {
                        let topics = (data.topic_list.topics).slice(2,11);
                        
                        topics.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                        
                        topics.forEach(topic => {
                            const date = new Date(topic.created_at);
                            topic.created_at = date.toLocaleDateString("en-US", {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            });
                        });
        
                        done(topics);
                    });
                },
                pageSize: 3,
                ulClassName: 'pagination justify-content-center',
                prevText: '&lsaquo;',
                nextText: '&rsaquo;',
                callback: function (data, pagination) {
                    const html = discussTemplate({
                        discuss: data
                    });
                    $('.discuss-items .row').html(html);
                }
            });
        }

        if (window.location.pathname.includes('/blog')) {
            $('.discuss-blog-pagination').pagination({
                dataSource: function (done) {
                    fetch('https://discuss.secdim.com/c/blog/28/l/latest.json')
                    .then((response) => response.json())
                    .then((data) => {
                        let topics = data.topic_list.topics;
                        
                        topics.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                        
                        topics.forEach(topic => {
                            const date = new Date(topic.created_at);
                            topic.created_at = date.toLocaleDateString("en-US", {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            });
                        });
        
                        done(topics);
                    });
                },
                pageSize: 9,
                ulClassName: 'pagination justify-content-center',
                prevText: '&lsaquo;',
                nextText: '&rsaquo;',
                callback: function (data, pagination) {
                    const html = blogTemplate({
                        discuss: data
                    });
                    $('.discuss-blog-items .row').html(html);
                }
            });
        }
        




        // Prism JS
        setTimeout(() => {
            Prism.highlightAll();
        }, 0);

        // Defensive by design menu
        const $menuItemsContainer = $('.snippets-code__languages');
        const $snippets = $('.snippets-code__wrap');

        if ($menuItemsContainer) {

            $menuItemsContainer.on('click', 'a', function (event) {
                const $self = $(this);
                event.preventDefault();

                $menuItemsContainer.find('li').removeClass('active');
                $self.parent('li').addClass('active');


                const snippetLang = $self.data('snippet');

                $snippets.each(function (index, item) {
                    $(item).addClass('d-none');
                });

                $snippets.filter(`[data-code='${snippetLang}']`).removeClass('d-none');

            });
        }
    });



}());

jQuery(document).on('submit', '#oldcontactform', function(event) {
    try {
      var $form = jQuery(this);
      event.preventDefault();
      // register($form);
    } catch(error){}
});


window.toggleHide = function() {
    var x = document.getElementById("video_overlay");
    var y = document.getElementById("yt_video");
    var video = document.getElementById("video");
    // var videoEmbed = video.getAttribute('src');
    // video.setAttribute('src', videoEmbed + '?autoplay=1');
    x.style.display = "none";
    y.style.display = "block";
    video.play();
}
window.toggleHover = function(show) {
    var x = document.getElementById("video-overlay");
    if(show) {
        x.setAttribute('src','../assets/images/hero/battle-video-poster2-overlay.png');
    }
    else {
        x.setAttribute('src','../assets/images/hero/battle-video-poster2.png');
    }
}

const updateCopyrightYear = () => {
    const year = new Date().getFullYear();
    const elements = document.querySelectorAll('.current-year');
    elements.forEach(element => {
        element.textContent = year;
    });
}

document.addEventListener('DOMContentLoaded', (event) => {
    // Update the copyright year
    updateCopyrightYear();
});






// Blog page logic


document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname.includes('/post')) {
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('id');
    
    if (postId && /^\d+$/.test(postId)) {
      displayBlogPost(postId);
    } else {
      console.error('Invalid post ID');
      updateBlog404();
    }
  }
});

function updateReadOnDiscussLink() {
  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');
  const id = urlParams.get('id');
  
  if (slug && id && /^\d+$/.test(id) && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(slug)) {
    const discussLink = document.querySelector('.discuss-btn');
    if (discussLink) {
      discussLink.href = `https://discuss.secdim.com/t/${slug}/${id}`;
    } else {
      console.error('Discuss link element not found');
    }
  } else {
    console.error('Slug or ID not found or invalid');
  }
}

function updateBlogContent(post) {
  const tempDiv = document.createElement('div');
  tempDiv.classList.add('cooked');
  tempDiv.innerHTML = post.cooked;

  // Remove elements with the class "meta"
  const metaElements = tempDiv.getElementsByClassName('meta');
  while (metaElements[0]) {
    metaElements[0].parentNode.removeChild(metaElements[0]);
  }

  // Center align all <img> elements with width greater than 350px
  const imgElements = tempDiv.getElementsByTagName('img');
  for (let img of imgElements) {
    if (img.width > 350 || img.height > 350) {
      img.style.display = 'block';
      img.style.margin = 'auto';
      // img.style.width = '50%';
      // img.style.minWidth = '250px'
      img.style.height = 'auto';
    }
  }

  // Center align and adjust the width of <img> elements within lightbox-wrapper
  const lightboxWrappers = tempDiv.getElementsByClassName('lightbox-wrapper');
  for (let wrapper of lightboxWrappers) {
    const img = wrapper.querySelector('img');
    if (img) {
      img.style.margin = '0 auto';
      img.style.width = '100%';
    }
    // Add a <br/> after each lightbox-wrapper
    const br = document.createElement('br');
    wrapper.parentNode.insertBefore(br, wrapper.nextSibling);
  }

  // Function to calculate total text length
  function calculateTotalTextLength(element) {
    const textElements = element.querySelectorAll('p, li, ul, ol, h1, h2, h3, a, blockquote, pre, code');
    let totalLength = 0;
    textElements.forEach(el => {
      totalLength += el.textContent.length;
    });
    return totalLength;
  }

  // Calculate reading time
  const totalLength = calculateTotalTextLength(tempDiv);
  const readingTime = Math.ceil(totalLength / (863 * 3));

  // Create a header using the slug
  const title = post.topic_slug.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());

  const headerDiv = document.createElement('div');
  headerDiv.className = 'blog-title';
  headerDiv.innerHTML = `
    <h1>${title}</h1>
    <div class="blog-meta">
      <div class="last-edited">${new Date(post.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })} - ${readingTime} minute${readingTime > 1 ? 's' : ''} read</div>
    </div>
    <br/>
  `;

  // Replace the article with the new content
  const articleElement = document.querySelector('.hero-content');
  if (articleElement) {
    const blogContentDiv = document.createElement('div');
    blogContentDiv.className = 'blog-content';
    blogContentDiv.appendChild(headerDiv);
    blogContentDiv.appendChild(tempDiv);
    articleElement.replaceWith(blogContentDiv);

    // Code highlighting
    document.querySelectorAll('pre code').forEach((block) => {
      setTimeout(() => {
          Prism.highlightAll();
      }, 0);
    });

    // Embed YouTube video
    const videoContainer = document.querySelector('.youtube-onebox.lazy-video-container');
    if (videoContainer) {
        const videoId = videoContainer.getAttribute('data-video-id');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.youtube.com/embed/${videoId}`;
        iframe.title = videoContainer.getAttribute('data-video-title');
        iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
        iframe.allowFullscreen = true;
        
        videoContainer.innerHTML = '';
        videoContainer.appendChild(iframe);
    }

    updateReadOnDiscussLink();

    // Load authors JSON and update the footer
    fetch('/authors.json')
      .then(response => response.json())
      .then(authors => {
        const author = authors.find(a => a.username === post.username);
        if (author) {
          updateFooter(author);
        } else {
          console.error('Author not found');
        }
      })
      .catch(error => console.error('Error loading authors:', error));
  } else {
    console.error('Article element not found');
  }
}

function updateFooter(author) {
  const footerDiv = document.createElement('section');
  footerDiv.className = 'action';
  footerDiv.innerHTML = `
    <div class="container author-section">
      <div class="action-content">
        <div class="action-wrap">

          <div class="action-deco__top">
            <img src="../assets/images/decos/deco-line-horizontal.png" alt="Horizontal Deco">
          </div> <!--/.action-deco__top-->

          <div class="action-deco__bottom">
            <img src="../assets/images/decos/deco-line-horizontal.png" alt="Horizontal Deco">
          </div> <!--/.action-deco__bottom-->

          <article class="author-article d-flex align-items-center">
            <img src="../assets/images/authors/${author.image}" alt="${author.name}" class="author-photo">
            <div class="author-details ml-3">
              <h3>${author.name}</h3>
              <p>${author.bio}</p>
            </div>
          </article> <!--/.action-article-->

        </div> <!--/.action-wrap-->
      </div> <!--/.action-content-->
    </div> <!--/.container-->
  `;

  const blogContentDiv = document.querySelector('.blog-content');
  if (blogContentDiv) {
    blogContentDiv.appendChild(footerDiv);
  } else {
    console.error('Blog content element not found');
  }
}

function updateBlog404() {
  const headerDiv = document.createElement('div');
  headerDiv.className = 'discuss-content';
  headerDiv.innerHTML = `
    <header class="discuss-snippets-header text-center">
      <h1>Page Not Found</h1>
      <p>Please head to the <a href="/blog">blog</a> page and try again</p>
    </header>
  `;

  // Replace the article with the 404 message
  const articleElement = document.querySelector('.hero-article');
  if (articleElement) {
    const blogContentDiv = document.createElement('div');
    blogContentDiv.className = 'blog-content';
    blogContentDiv.appendChild(headerDiv);
    articleElement.replaceWith(blogContentDiv);
  } else {
    console.error('Article element not found');
  }
}

function displayBlogPost(postId) {
  fetch(`https://discuss.secdim.com/t/${postId}.json`)
    .then(response => response.json())
    .then(data => {
      if (data.post_stream && data.post_stream.posts && data.post_stream.posts.length > 0) {
        const post = data.post_stream.posts[0];
        updateBlogContent(post);
      } else {
        updateBlog404();
      }
    })
    .catch(error => {
      console.error('Error fetching blog post:', error);
      updateBlog404();
    });
}

